





















































































import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import InviteUserInput from '@/components/invite-user/InviteUserInput.vue';
import InviteUserSelect from '@/components/invite-user/InviteUserSelect.vue';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import { ERole } from '@/api/schema';
import { allUserRoles } from '@/helpers/inviteUserHelpers';
import { textOverflowMiddle } from '@/helpers/formatString';
import { resendInvite, deleteInvite } from '@/api/Onboarding';
import BaseDialog from '@/components/BaseDialog.vue';

const InviteUserList = (): any =>
  import('@/components/invite-user/InviteUserList.vue');

export default {
  name: 'InviteUserDialog',
  components: {
    BaseDialog,
    InviteUserInput,
    CodeBlock,
    InviteUserList,
    InviteUserSelect
  },
  data(): any {
    return {
      email: '',
      selectedAccounts: [],
      active: true,
      currentType: null
    };
  },
  computed: {
    ...mapState('Onboarding', [
      'inviteUserDialog',
      'inviteUsers',
      'operators',
      'inviteLinks'
    ]),
    ...mapGetters('Onboarding', ['operatorListByGroup', 'currentAccount']),
    ...mapGetters('app', ['isCopyStakeRole']),
    currentLink(): string {
      if (!this.operatorIds.length) return;

      const query =
        this.inviteUserDialog.type || this.currentType || this.$role.name;

      const currentInvite = this.inviteLinks.find(
        ({ role }) => role === query
      );

      if (currentInvite?.link) return currentInvite.link;

      const baseInvite = this.inviteLinks.find(({ role }) =>
        role === this.isCopyStakeRole
          ? ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN
          : ERole.ROLE_OPERATOR_ADMIN
      );

      return baseInvite?.link;
    },
    computedName(): string {
      const query =
        this.inviteUserDialog.type || this.currentType || this.$role.name;

      return allUserRoles.find(({ value }) => value === query)?.label;
    },
    computedLink(): string {
      return textOverflowMiddle(this.currentLink, 20, 20);
    },
    operatorIds(): number[] {
      if (!this.currentAccount) return [];

      if (this.inviteUserDialog.type || !this.showSelectOperator) {
        return this.currentAccount.children.map(({ id }) => id);
      }

      return this.selectedAccounts.flat().map(({ id }) => id);
    },

    showSelectOperator(): boolean {
      return (
        !this.inviteUserDialog.type && this.operatorListByGroup.length > 1
      );
    },

    disabledInviteButton(): boolean {
      return this.showSelectOperator && !this.selectedAccounts.length;
    }
  },
  watch: {
    operatorIds: {
      handler(arr: number[]): void {
        if (arr.length) {
          this.getInviteLinks(arr);
        }
      },
      immediate: true
    },
    'inviteUserDialog.show': {
      handler(open: boolean): void {
        if (open) this.getInviteUsers();
      }
    }
  },
  methods: {
    ...mapMutations('Onboarding', ['setInviteUserDialogOptions']),
    ...mapActions('Onboarding', ['getInviteLinks', 'getInviteUsers']),

    closeDialog(): void {
      this.active = false;
      setTimeout(() => {
        this.setInviteUserDialogOptions({ show: false });
        this.active = true;
        this.selectedAccounts = [];
        this.email = '';
      }, 300);
    },

    copiedCallback(): void {
      this.getInviteLinks(this.operatorIds);
      if (this.inviteUserDialog.type === ERole.ROLE_OPERATOR_DEVELOPER) {
        window.dataLayer.push({
          event: 'invite_developer_pop_up',
          eventCategory: 'onboarding',
          eventAction: 'click',
          eventLabel: 'invite_developer_link_popup'
        });
      }
    },

    updateList(): void {
      this.getInviteUsers();
      this.selectedAccounts = [];
      this.email = '';
    },

    resendInviteUser(id: number): void {
      resendInvite(id).then(() => {
        this.$toast.success(
          'The invitation has been successfully sent again'
        );
        this.getInviteUsers();
      });
    },

    deleteInviteUser(id: number): void {
      deleteInvite(id).then(() => {
        this.$toast.success('The invitation was successfully removed');
        this.getInviteUsers();
      });
    }
  }
};
