

















import {
  requestAccountEmailVerifyLetter,
  requestEmailVerifyLetter
} from '@/api/Auth';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { mapGetters } from 'vuex';

const EVENT_TYPE = 'TP_RESEND_EMAIL';
const EVENT_KEY = 'email_resend_timeout';
const TIMER_DURATION = 59 * 1000; // 1 minute

export default {
  name: 'EmailVerifyButton',
  data(): any {
    return {
      left: 0
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['isEmailVerified']),
    message(): string {
      if (this.left) {
        const time = `${this.left}`.padStart(2, '0');

        return ` (${time} sec)`;
      }

      return '';
    }
  },
  created(): void {
    addEventListener('message', this.handleEvent, false);
  },
  mounted(): void {
    this.start();
  },
  beforeDestroy(): void {
    removeEventListener('message', this.handleEvent);
  },
  methods: {
    handleEvent({ data }: MessageEvent): void {
      if (data?.type === EVENT_TYPE) {
        this.reset();
      }
    },
    pushEvent(): void {
      if (this.left) return;

      postMessage({ type: EVENT_TYPE }, location.origin);
    },
    request(): void {
      if (this.isEmailVerified) return;

      const handler = this.$role.is(['account', 'copystake-account'])
        ? requestAccountEmailVerifyLetter
        : requestEmailVerifyLetter;

      handler().then(this.pushEvent).catch(errorToastMessage);
    },
    reset(): void {
      localStorage.setItem(EVENT_KEY, `${Date.now() + TIMER_DURATION}`);
      this.left = TIMER_DURATION / 1000;
      this.start();
    },
    start(): void {
      const now = Date.now();
      const timeout = +(localStorage.getItem(EVENT_KEY) || 0);

      if (now < timeout) {
        this.left = Math.trunc((timeout - now) / 1000);

        setTimeout(this.start, 1000);
      } else {
        localStorage.removeItem(EVENT_KEY);
        this.left = 0;
      }
    }
  }
};
