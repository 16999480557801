











import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { eventBus } from '@/main';
import { itemLightBox } from '@/api/schema';

interface dataInteface {
  items: itemLightBox[];
  activeIndex: null | number;
}

export default {
  name: 'LightBox',
  components: {
    CoolLightBox
  },
  data: (): dataInteface => ({
    items: [],
    activeIndex: null
  }),
  mounted(): void {
    eventBus.$on(
      'setLightBoxItems',
      (data: itemLightBox[], index: number = 0) => {
        this.items = data;
        this.activeIndex = index;
      }
    );
  },
  beforeUnmount(): void {
    eventBus.$off();
  },
  methods: {
    openLightBox(): void {
      document.documentElement.style.overflowY = 'hidden';
    },
    closeLightBox(): void {
      document.documentElement.style.overflowY = 'auto';
      this.data = [];
      this.activeIndex = null;
    }
  }
};
