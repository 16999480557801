





























































import { PropType } from 'vue';
import { AccountOperator, IAccountListItem } from '@/api/schema';
import { mapGetters, mapState } from 'vuex';
import { groupOperatorsByAccounts } from '@/helpers/operatorHelpers';

export default {
  name: 'OperatorDropdown',

  props: {
    operators: {
      type: Array,
      default: (): [] => []
    },
    selected: {
      type: Object as PropType<AccountOperator | null>,
      default: (): any => null
    }
  },

  data(): any {
    return {
      openMenu: false
    };
  },

  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['currentBrandName']),

    showDropdown(): boolean {
      return this.operators.length > 1 || this.isSuperAdmin;
    },
    operatorList(): IAccountListItem[] {
      return groupOperatorsByAccounts(this.operators);
    },
    activeItemId(): number {
      if (this.operatorList.length === 1) return 0;

      return this.operatorList.findIndex(
        (el: IAccountListItem) =>
          el.name.toLowerCase() === this.currentBrandName.toLowerCase()
      );
    }
  },

  methods: {
    selectedOperator(operator: AccountOperator): void {
      this.$emit('select', operator);
      this.openMenu = false;
    },

    addAccount(): void {
      if (!this.isSuperAdmin) return;

      this.openMenu = false;
      this.$emit('add-account');
    }
  }
};
