








































































import { mapActions, mapState } from 'vuex';
import { allUserRoles } from '@/helpers/inviteUserHelpers';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { textOverflowMiddle } from '@/helpers/formatString';

export default {
  name: 'NavBarUserMenu',
  components: {
    Tooltip
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('Onboarding', ['accountInfo']),
    accountRole(): string {
      if (this.$role.is(['account', 'copystake-account'])) return 'Account';

      const userRole = allUserRoles.find(
        (role) => role.value === this.$role.name
      );
      return userRole?.label || '';
    }
  },
  methods: {
    textOverflowMiddle,
    ...mapActions('Auth', ['logout']),
    ...mapActions('Onboarding', ['resetOnboardingFormData']),
    logoutAction(): void {
      this.logout().then(() => {
        this.resetOnboardingFormData();
        this.$router.push('/login');
      });
    }
  }
};
