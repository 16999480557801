








import Logo from '@/assets/images/Logo.svg?inline';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AppLoader',
  components: { Logo },
  data(): any {
    return {
      progress: 0,
      show: true
    };
  },
  computed: {
    ...mapState('app', ['loadComplete'])
  },
  mounted(): void {
    const increaseInterval = (): void => {
      if (this.progress < (this.loadComplete ? 100 : 90)) {
        this.progress += 1;
      }

      if (this.progress < 100) {
        const modifier = this.loadComplete ? 10 : 5;
        const timeout = (100 - this.progress) / modifier;

        setTimeout(increaseInterval, timeout);
      } else {
        setTimeout(() => {
          this.show = false;

          setTimeout(this.SET_LOADER_HIDE, 300);
        }, 200);
      }
    };

    this.$nextTick(increaseInterval);
  },
  methods: {
    ...mapMutations('app', ['SET_LOADER_HIDE'])
  }
};
