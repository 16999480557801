var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{staticClass:"invite-user-dialog",attrs:{"title":_vm.inviteUserDialog.title || 'Invite members',"max-width":_vm.inviteUserDialog.type ? '664px' : '500px',"value":_vm.inviteUserDialog.show && _vm.active},on:{"close":_vm.closeDialog},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.inviteUserDialog.type)?_c('div',{staticClass:"invite-user-dialog__header"},[_c('v-btn',{staticClass:"invite-user-dialog__close",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-window-close")])],1),_c('v-card-title',{staticClass:"invite-user-dialog__header flex-column pb-0",class:{ 'align-start': !_vm.inviteUserDialog.type }},[(!_vm.inviteUserDialog.hideTitle)?_c('span',[_vm._v(_vm._s(_vm.inviteUserDialog.title || 'Invite members'))]):_vm._e(),(_vm.inviteUserDialog.description)?_c('div',{staticClass:"invite-user-dialog__description"},[_vm._v(_vm._s(_vm.inviteUserDialog.description))]):_vm._e()])],1):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
var close = ref.close;
return [(_vm.showSelectOperator)?_c('v-select',{staticClass:"mb-5",attrs:{"items":_vm.operatorListByGroup,"label":"Select accounts","item-text":"name","item-value":"children","multiple":"","clearable":"","open-on-clear":"","chips":"","dense":"","outlined":"","validate-on-blur":"","hide-details":"auto","menu-props":{offsetY: true, contentClass: 'invite-user-dialog__menu', maxHeight: '192px'}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 3)?_c('v-chip',{staticClass:"pl-1",attrs:{"small":""}},[_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('v-chip',{attrs:{"small":""}},[_vm._v("+"+_vm._s(_vm.operatorListByGroup.length - 4))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"46px"}},'v-list-item',attrs,false),on),[_c('v-checkbox',{attrs:{"input-value":attrs.inputValue,"hide-details":""}}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.name))])],1)]}}],null,true),model:{value:(_vm.selectedAccounts),callback:function ($$v) {_vm.selectedAccounts=$$v},expression:"selectedAccounts"}}):_vm._e(),_c('InviteUserInput',{attrs:{"selected-operators-ids":_vm.operatorIds,"type":_vm.inviteUserDialog.type,"disable-button":_vm.disabledInviteButton},on:{"invite":_vm.updateList},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(!_vm.inviteUserDialog.type)?_c('InviteUserList',{staticClass:"mt-6",attrs:{"user-list":_vm.inviteUsers},on:{"delete":_vm.deleteInviteUser,"resend":_vm.resendInviteUser}}):_vm._e()]}},{key:"bottom",fn:function(){return [_c('div',{staticClass:"invite-user-dialog__bottom",class:{ 'pa-5 pt-1': _vm.inviteUserDialog.type }},[_c('CodeBlock',{attrs:{"value":_vm.currentLink,"custom-copy":"","copy":"","hide":false},on:{"copied":_vm.copiedCallback},scopedSlots:_vm._u([{key:"value",fn:function(){return [(_vm.inviteUserDialog.type)?[_vm._v(_vm._s(_vm.computedLink))]:[_vm._v("Invite as"),(_vm.$role.is(['account', 'admin', 'copystake-account', 'copystake-admin']))?_c('InviteUserSelect',{staticClass:"d-inline-flex ml-2 font-weight-bold",model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}}):_c('div',{staticClass:"d-inline-flex ml-2 font-weight-bold"},[_vm._v(_vm._s(_vm.computedName))])]]},proxy:true},{key:"copyBtn",fn:function(ref){
var showTooltip = ref.showTooltip;
return [_c('v-icon',{staticClass:"invite-user-dialog__icon mr-2",class:{'invite-user-dialog__icon--rotate': !showTooltip },attrs:{"size":"16","color":showTooltip ? 'success' : undefined},domProps:{"textContent":_vm._s(showTooltip ? 'mdi-checkbox-marked-circle' : 'mdi-link')}}),_c('div',{staticClass:"text-capitalize font-weight-regular invite-user-dialog__text",domProps:{"textContent":_vm._s(showTooltip ? 'Copied' : 'Copy Link')}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }