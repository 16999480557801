






























import { mapMutations, mapState, mapActions } from 'vuex';
import NavBarUserMenu from '@/components/core/nav-bar/NavBarUserMenu.vue';
import NavBarOperatorBalance from '@/components/core/nav-bar/NavBarOperatorBalance.vue';
import type { PropType } from 'vue';
import { AccountOperator } from '@/api/schema';
import NotificationSolidIcon from '@/assets/images/icons/notification-solid-icon.svg?inline';
import InviteUserIcon from '@/assets/images/icons/invite-user-icon.svg?inline';
import OperatorDropdown from '@/components/operator/OperatorDropdown.vue';

export default {
  name: 'NavBar',
  components: {
    OperatorDropdown,
    NavBarUserMenu,
    NavBarOperatorBalance,
    InviteUserIcon,
    NotificationSolidIcon
  },
  props: {
    menuActive: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('Onboarding', [
      'selectedOperator',
      'accountInfo',
      'operators'
    ])
  },
  methods: {
    ...mapMutations('Onboarding', [
      'setInviteUserDialogOptions',
      'setSelectedOperator'
    ]),

    ...mapActions('Onboarding', [
      'changeOperator',
      'resetOnboardingFormData',
      'showOperatorDialog',
      'getOnboardingFormData'
    ]),
    toggleInviteUsers(): void {
      this.setInviteUserDialogOptions({ show: true });
    },

    selectOperator(operator: AccountOperator): void {
      this.changeOperator(operator);

      if (operator.id) {
        this.getOnboardingFormData();
      } else {
        this.resetOnboardingFormData();
      }

      if (this.$role.can.read('home') && this.$route.path !== '/home') {
        this.$router.push('/home');
      }
    }
  }
};
