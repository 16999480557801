






















































































import { mapGetters, mapState } from 'vuex';
import { FixedPriceTokenEmissionData } from '@/api/schema';
import { getFixedPriceTokenEmissionData } from '@/api/TokensInfo';
import { toPercents } from '@/helpers/decimalsHelpers';
import { operatorCurrencyToUsdt } from '@/helpers/currencyHelper';
export default {
  name: 'NavBarOperatorBalance',
  data(): any {
    return {
      isOpen: false,
      tab: 0,
      tabs: ['Fixed price', 'Market Price'],
      tokenEmission: {} as FixedPriceTokenEmissionData
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('OperatorPayments', ['balance']),
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('ClientsInfo', ['pancakeSwapUrl']),
    ...mapGetters('ClientsInfo', ['isListed']),
    operatorBalance(): number {
      if (!+this.balance.currentBalance) {
        return 0;
      }
      return this.$options.filters.numeralSpaces(
        this.balance.currentBalance,
        '0,0.[00]'
      );
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loadData();
        }
      }
    }
  },
  methods: {
    toPercents,
    operatorCurrencyToUsdt,
    loadData(): void {
      Promise.all([getFixedPriceTokenEmissionData()])
        .then(([tokenEmission]) => {
          this.tokenEmission = tokenEmission;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
};
